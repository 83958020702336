class TaskDetailModel {
  constructor() {
    this.dnsMode = null;
    this.IPAddress = null;
    this.ExistingIPAddress = null;
    this.opSend = null;
    this.marketSend = null;
    this.noIp = null;
    this.createBrand = null;
    this.brandName = null;
    this.includeChildren = null;
    this.appendTags = null;
    this.createCloudDomain = null;
    this.cloudDomainVal = null;
    this.setRmm = null;
    this.rmmFwd = null;
    this.DNSWorkComplete = 'true';
    this.zoneRequested = 'true';
  }

  setDnsMode(dnsMode) {
    this.dnsMode = dnsMode;
  }

  getDnsMode() {
    return this.dnsMode;
  }

  setIPAddress(IPAddress) {
    this.IPAddress = IPAddress;
  }

  getIPAddress() {
    return this.IPAddress;
  }

  setopSend(opSend) {
    this.opSend = opSend;
  }

  getopSend() {
    return this.opSend;
  }

  setmarketSend(marketSend) {
    this.marketSend = marketSend;
  }

  getmarketSend() {
    return this.marketSend;
  }

  setnoIp(noIp) {
    this.noIp = noIp;
  }

  getnoIp() {
    return this.noIp;
  }

  setcreateBrand(createBrand) {
    this.createBrand = createBrand;
  }

  getcreateBrand() {
    return this.createBrand;
  }

  setincludeChildren(includeChildren) {
    this.includeChildren = includeChildren;
  }

  getincludeChildren() {
    return this.includeChildren;
  }

  setappendTags(appendTags) {
    this.appendTags = appendTags;
  }

  getappendTags() {
    return this.appendTags;
  }

  setcloudDomainVal(cloudDomainVal) {
    this.cloudDomainVal = cloudDomainVal;
  }

  getcloudDomainVal() {
    return this.cloudDomainVal;
  }

  setrmmFwd(rmmFwd) {
    this.rmmFwd = rmmFwd;
  }

  getrmmFwd() {
    return this.rmmFwd;
  }

  setDNSWorkComplete(DNSWorkComplete) {
    this.DNSWorkComplete = DNSWorkComplete;
  }

  getDNSWorkComplete() {
    return this.DNSWorkComplete;
  }

  setzoneRequested(zoneRequested) {
    this.zoneRequested = zoneRequested;
  }

  getzoneRequested() {
    return this.zoneRequested;
  }
}

export default TaskDetailModel;
