import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';

import './index.css';
import BounceVerify from './BounceVerify';
import DigTrace from './DigTrace';
import IPValidation from './IPValidation';
import DseLookup from './DseLookup';
import ZoneFileGenerator from './ZoneFileGenerator';
import TaskDetailGenerator from './TaskDetailGenerator';
// import NSVerification from './NSVerification';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <IconSettings iconPath="/assets/icons">
        <Routes>
          <Route path="ui/bounce-verify" element={<BounceVerify />} />
          <Route path="ui/dig-trace" element={<DigTrace />} />
          <Route path="ui/ip-validation" element={<IPValidation />} />
          <Route path="ui/dse-lookup" element={<DseLookup />} />
          <Route path="ui/zone-file-generator" element={<ZoneFileGenerator />} />
          <Route path="ui/task-detail-generator" element={<TaskDetailGenerator />} />
          {/* <Route path="ui/ns-verify" element={<NSVerification />} /> */}
        </Routes>
      </IconSettings>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
