import React, { useState } from 'react';

const getZoneFileContents = async (domain, mtaIp, stack) => {
  const response = await fetch(`/zone-file-generator/?d=${domain}&i=${mtaIp}&s=${stack}`);
  return response.status === 200 ? response.text() : 'Error';
};

const ZoneFileGenerator = () => {
  // State to store input values
  const [domain, setDomain] = useState('');
  const [mtaIp, setMtaIp] = useState('');
  const [stack, setStack] = useState('S1');
  const [result, setResult] = useState('');

  // Handler to generate the result based on the inputs
  const generateResult = async () => {
    const generatedResult = await getZoneFileContents(domain, mtaIp, stack);
    setResult(generatedResult);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    generateResult();
  };

  const handleReset = () => {
    setDomain('');
    setMtaIp('');
    setStack('S1');
    setResult('');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(result);
    // eslint-disable-next-line no-alert
    alert('Copied to clipboard!');
  };

  const handleDownload = () => {
    const blob = new Blob([result], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'zone-file.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      className="zone-file-generator"
      style={{
        display: 'flex', height: '100vh', padding: '20px', boxSizing: 'border-box',
      }}
    >
      <div style={{ width: '300px', marginRight: '20px' }}>
        <form onSubmit={handleSubmit}>
          <div>
            Domain:
            <input
              type="text"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </div>
          <div>
            MTA IP:
            <input
              type="text"
              value={mtaIp}
              onChange={(e) => setMtaIp(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </div>
          <div>
            Stack:
            <select
              value={stack}
              onChange={(e) => setStack(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            >
              <option value="S1">S1</option>
              <option value="S4">S4</option>
              <option value="S6">S6</option>
              <option value="S7">S7</option>
              <option value="S10">S10</option>
              <option value="S11">S11</option>
              <option value="S12">S12</option>
              <option value="S13">S13</option>
              <option value="S50">S50</option>
              <option value="S51">S51</option>
              <option value="mj400">mj400</option>
              <option value="mj401">mj401</option>
              <option value="mj402">mj402</option>
              <option value="mj403">mj403</option>
              <option value="mj404">mj404</option>
            </select>
          </div>
          <div>
            <button type="submit" style={{ marginRight: '10px', backgroundColor: 'green', color: 'white' }}>Submit</button>
            <button type="button" onClick={handleReset} style={{ backgroundColor: 'gray', color: 'white' }}>Reset</button>
          </div>
        </form>
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '10px' }}>
          <button type="button" onClick={handleCopy} style={{ marginRight: '10px', backgroundColor: 'blue', color: 'white' }}>Copy</button>
          <button type="button" onClick={handleDownload} style={{ backgroundColor: 'orange', color: 'white' }}>Download</button>
        </div>
        <textarea
          value={result}
          readOnly
          style={{
            flex: 1, padding: '10px', boxSizing: 'border-box', resize: 'none',
          }}
        />
      </div>
    </div>
  );
};

export default ZoneFileGenerator;
