import PropTypes from 'prop-types';
import React from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';

const IPSenderScoreDataTableCell = ({ children, ...props }) => {
  const { score, url } = props.item.senderScore;

  return (
    <DataTableCell {...props}>
      <a href={url}>{score}</a>
    </DataTableCell>
  );
};

IPSenderScoreDataTableCell.displayName = DataTableCell.displayName;
IPSenderScoreDataTableCell.propTypes = {
  children: PropTypes.node.isRequired,
  item: PropTypes.shape({
    senderScore: {
      score: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }.isRequired,
  }).isRequired,
};

export default IPSenderScoreDataTableCell;
