/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState } from 'react';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import Spinner from '@salesforce/design-system-react/components/spinner';

import './DigTrace.scss';

const DigTrace = () => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [domain, setDomain] = useState();
  const [domainTrace, setDomainTrace] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [timestamp, setTimestamp] = useState();
  const [valid, setValid] = useState();

  const checkDelegation = async () => {
    if (buttonDisabled) return;

    setLoading(true);
    setDomainTrace();
    setTimestamp();
    setValid();

    try {
      const trimmedDomain = domain.trim();
      const formattedDomain = encodeURIComponent(trimmedDomain);
      const traceResponse = await fetch(`/dig-txt-trace/${formattedDomain}`);

      if (traceResponse.status === 200) {
        const trace = await traceResponse.text();

        const isValid = trace
          .split('\n\n')
          .filter((section) => section.includes(trimmedDomain))
          .find((section) => section.includes('ns2.exacttarget.com'))
          !== undefined;

        if (isValid) {
          setValid(true);
          setDomainTrace(trace);
        } else {
          const nsResponse = await fetch(`/dig-ns/${formattedDomain}`);
          const nsText = await nsResponse.text();

          if (nsResponse.status === 200) {
            const nsValid = nsText.includes('ns2.exacttarget.com');
            setValid(nsValid);
            setDomainTrace(nsValid ? nsText : trace);
          } else {
            const { error } = await nsResponse.json();
            setErrorMessage(error);
          }
        }

        setTimestamp(new Date().toUTCString().replace('GMT', 'UTC'));
      } else {
        const { error } = await traceResponse.json();
        setErrorMessage(error);
      }
    } catch (err) {
      setErrorMessage(err.message);
    }

    setLoading(false);
  };

  const checkSearchKeyDown = (event) => event.key === 'Enter' && checkDelegation();

  const updateDomain = (_, { value }) => {
    setErrorMessage();
    setDomain(value);
    setButtonDisabled(value.length === 0);
  };

  return (
    <div className="slds-grid">
      <div className="slds-col slds-size_1-of-6" />
      <div className="slds-col slds-size_2-of-3">
        <div className="slds-text-heading_large slds-p-top_medium">
          Check Domain Delegation
        </div>

        <div className="search-bar">
          <Input placeholder="example.domain.com" onChange={updateDomain} onKeyDown={checkSearchKeyDown} />
          <Button label="Check delegation" variant="brand" onClick={checkDelegation} disabled={buttonDisabled} />
        </div>

        <div className="slds-p-top_x-small slds-text-color_error">{errorMessage}</div>

        {loading && <Spinner variant="brand" size="large" />}

        {valid === true && <div className="slds-p-top_xx-small dig-trace-message">✅ Domain has been delegated correctly</div>}
        {valid === false && <div className="slds-p-top_xx-small dig-trace-message">❌ Domain has not been delegated correctly</div>}

        <pre>{domainTrace}</pre>

        {timestamp && (
          <div className="slds-p-vertical_small dig-trace-message">Ran at: {timestamp}</div>
        )}
      </div>
      <div className="slds-col slds-size_1-of-6" />
    </div>
  );
};

export default DigTrace;
