import React, { useRef, useState } from 'react';
import Button from '@salesforce/design-system-react/components/button';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Textarea from '@salesforce/design-system-react/components/textarea';

import IPCheckDataTableCell from './IPCheckDataTableCell';
import IPSenderScoreDataTableCell from './IPSenderScoreDataTableCell';

import './IPValidation.scss';

const IPValidation = () => {
  const [loading, setLoading] = useState(false);
  const [ipResults, setIPResults] = useState([]);

  const ipList = useRef([]);

  const checkIPList = async () => {
    setLoading(true);

    const response = await fetch('/bulk-ip-validate', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ips: ipList.current }),
    });

    setIPResults(await response.json());
    setLoading(false);
  };

  const updateIPList = (event) => {
    ipList.current = event.target.value
      .split('\n')
      .filter((value) => value.length);
  };

  return (
    <div className="slds-grid">
      {loading && <Spinner size="large" />}

      <div className="slds-col slds-size_1-of-6" />
      <div className="slds-col slds-size_2-of-3">
        <div className="slds-text-align_center slds-text-heading_large slds-p-top_large">
          IP Validation
        </div>

        <Textarea
          className="ip-validation-textarea"
          onBlur={updateIPList}
          placeholder="One IP or CIDR per line. CIDR ranges up to /24 are supported. Please note that requesting a lot of large ranges will take some time to load."
        />

        <Button
          disabled={loading}
          label="Validate IP Addresses"
          onClick={checkIPList}
          style={{ width: '100%' }}
          variant="brand"
        />

        {ipResults.length > 0 && (
          <DataTable
            className="slds-m-vertical_large slds-border_left slds-border_right slds-border_bottom"
            items={ipResults}
          >
            <DataTableColumn key="ip" label="IP Address" property="ip" />
            <DataTableColumn key="hostnames" label="Hostnames" property="hostnames" />
            <DataTableColumn key="spamCop" label="SpamCop" property="spamCop">
              <IPCheckDataTableCell serviceKey="spamCop" />
            </DataTableColumn>
            <DataTableColumn key="spamhaus" label="Spamhaus" property="spamhaus">
              <IPCheckDataTableCell serviceKey="spamhaus" />
            </DataTableColumn>
            <DataTableColumn key="barracuda" label="Barracuda" property="barracuda">
              <IPCheckDataTableCell serviceKey="barracuda" />
            </DataTableColumn>
            <DataTableColumn key="senderScore" label="Sender Score" property="senderScore">
              <IPSenderScoreDataTableCell />
            </DataTableColumn>
          </DataTable>
        )}
      </div>
      <div className="slds-col slds-size_1-of-6" />
    </div>
  );
};

export default IPValidation;
