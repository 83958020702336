/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import React, { useState } from 'react';
import Button from '@salesforce/design-system-react/components/button';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import DropdownTrigger from '@salesforce/design-system-react/components/menu-dropdown/button-trigger';
import Input from '@salesforce/design-system-react/components/input';

import './DseLookup.scss';

const createHashDomain = async (domain, domainType, isQA) => {
  const response = await fetch(`/dse-lookup/?d=${domain}&t=${domainType}&q=${isQA}`);
  return response.status === 200 ? response.text() : 'Error';
};

const HashedDomainGenerator = () => {
  const [domain, setDomain] = useState('');
  const [domainType, setDomainType] = useState('cloud');
  const [domainTypeLabel, setDomainTypeLabel] = useState('Cloud');
  const [isQA, setIsQA] = useState(false);
  const [result, setResult] = useState('');

  const generateHashedDomain = async () => {
    const value = await createHashDomain(domain, domainType, isQA);
    setResult(value);
  };

  const textAreaUpdated = ({ target: { value } }) => {
    if (value === '') setDomain('');
    else {
      setDomain(value.split('\n')
        .map((d) => d.trim())
        .filter((d) => d.length > 0)[0]);
    }
  };

  return (
    <div className="slds-grid test">
      <div className="slds-col slds-size_1-of-8" />
      <div className="slds-col slds-size_3-of-4">
        <div className="slds-text-heading_large slds-m-top_medium">
          DSE Lookup
        </div>

        <Input
          className="slds-m-top_medium"
          onChange={textAreaUpdated}
          onKeyPress={(e) => { if (e.key === 'Enter') generateHashedDomain(); }}
          placeholder="Enter a domain"
          on
        />

        Select Domain Type:&nbsp;
        <IconSettings iconPath="/assets/icons">
          <Dropdown
            onSelect={(e) => { setDomainTypeLabel(e.label); setDomainType(e.value); }}
            options={[
              { label: 'Cloud', value: 'cloud' },
              { label: 'Click', value: 'click' },
              { label: 'Image', value: 'image' },
              { label: 'Pages', value: 'pages' },
              { label: 'View', value: 'view' },
            ]}
          >
            <DropdownTrigger>
              <Button
                iconCategory="utility"
                iconName="down"
                iconPosition="right"
                label={domainTypeLabel}
              />
            </DropdownTrigger>
          </Dropdown>
        </IconSettings>
        <div>
          Is QA:&nbsp;
          <input type="checkbox" checked={isQA} onChange={() => setIsQA(!isQA)} />
        </div>
        <div>
          <Button onClick={generateHashedDomain}>Generate Hashed Domain</Button>
        </div>
        <IconSettings iconPath="/assets/icons">
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <strong>{result}</strong>
            </div>
          </section>
        </IconSettings>
      </div>
    </div>
  );
};

export default HashedDomainGenerator;
