/* task detail generator for dsos jobs */
/* eslint-disable max-len */
import React from 'react';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import TaskDetailModel from './TaskDetailModel';
import CustomRadioButton from './CustomRadioButton';

class TaskDetailGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: new TaskDetailModel(),
      jsonVal: '',
    };
  }

  handleRadioOptionChange = (modelProp, value) => {
    const { model } = this.state;
    let { jsonVal } = this.state;
    model[modelProp] = value;
    jsonVal = '';
    this.setState({ model, jsonVal });
  };

  generateJson = () => {
    const { model } = this.state;
    let { jsonVal } = this.state;
    const newModel = JSON.parse(JSON.stringify(model));
    if (newModel.IPAddress === 'sapNoip') {
      newModel.noIp = 'true';
    }
    if (newModel.IPAddress === 'claimNew') {
      newModel.ExistingIPAddress = null;
      newModel.IPAddress = null;
    } else if (newModel.IPAddress === 'existingIp') {
      newModel.IPAddress = newModel.ExistingIPAddress;
      newModel.ExistingIPAddress = null;
      newModel.noIp = 'true';
    } else if (newModel.IPAddress === 'sharedIp') {
      newModel.ExistingIPAddress = null;
      newModel.IPAddress = null;
      newModel.noIp = 'true';
    } else if (newModel.IPAddress === 'customIp') {
      newModel.IPAddress = newModel.ExistingIPAddress;
      newModel.ExistingIPAddress = null;
      newModel.noIp = null;
    } else {
      newModel.ExistingIPAddress = null;
      newModel.opSend = null;
      newModel.marketSend = null;
    }

    if (newModel.createBrand === 'Yes') {
      newModel.createBrand = 'true';
    } else {
      newModel.createBrand = 'false';
      newModel.brandName = null;
      newModel.appendTags = null;
      newModel.includeChildren = null;
    }

    if (newModel.opSend === 'Yes') {
      newModel.opSend = '1';
    } else if (newModel.opSend === 'No') {
      newModel.opSend = '0';
    } else {
      newModel.opSend = null;
    }

    if (newModel.marketSend === 'Yes') {
      newModel.marketSend = '1';
    } else if (newModel.marketSend === 'No') {
      newModel.marketSend = '0';
    } else {
      newModel.marketSend = null;
    }

    if (newModel.createCloudDomain === 'Yes') {
      newModel.createCloudDomain = 'true';
    } else {
      newModel.createCloudDomain = 'false';
      newModel.cloudDomainVal = null;
    }

    if (newModel.setRmm !== 'Yes') {
      newModel.rmmFwd = null;
    }

    if (newModel.appendTags === 'Yes') {
      newModel.appendTags = 'true';
    } else {
      newModel.appendTags = null;
    }

    if (newModel.includeChildren === 'Yes') {
      newModel.includeChildren = 'true';
    } else {
      newModel.includeChildren = null;
    }
    newModel.setRmm = null;

    if (newModel.zoneRequested === 'No') {
      newModel.zoneRequested = null;
    } else {
      newModel.zoneRequested = 'true';
    }

    const jsonValNew = JSON.stringify(newModel, (key, value) => (value !== null ? value : undefined));
    jsonVal = jsonValNew;
    this.setState({ model, jsonVal });
  };

  render() {
    const { model, jsonVal } = this.state;
    const IpAddressValues = ['claimNew', 'sharedIp', 'existingIp', 'customIp'];
    const Iplabels = { label: 'IP Address' };
    const DNSModeValues = ['HOSTED', 'DELEGATED'];
    const DNSModeLabels = { label: 'DNS Mode' };
    const YesNoValues = ['Yes', 'No'];
    const CreateBrandLabel = { label: 'Create Brand' };
    const CreateCloudPage = { label: 'Create CloudPage' };
    const OpSendLabel = { label: 'Operational Send' };
    const MarketSendLabel = { label: 'Market Send' };
    const RMMLabel = { label: 'Setup RMM' };
    const AppendTagsLabel = { label: 'Append Tags' };
    const IncludeChildrenLabel = { label: 'Include Children' };
    const ZoneRequestLabel = { label: 'Zone Request (Select No for cloudpage only Request)' };

    return (
      <div className="slds-grid test">
        <div className="slds-col slds-size_1-of-8" />
        <div className="slds-col slds-size_3-of-4">
          <div className="slds-text-heading_large slds-m-top_medium">
            Task Detail Generator
          </div>
          <CustomRadioButton
            labels={Iplabels}
            groupName="IpAddress"
            modelProName="IPAddress"
            radioMap={IpAddressValues}
            model={model}
            inputRequired="true"
            inputConditionText={['existingIp', 'customIp']}
            inputText="please input existing Ip to use"
            inputValue="ExistingIPAddress"
            handleFunc={this.handleRadioOptionChange}
          />
          {(model.IPAddress === 'claimNew' || model.IPAddress === 'existingIp' || model.IPAddress === 'customIp') && (
            <>
              <CustomRadioButton
                labels={OpSendLabel}
                groupName="Operational Send"
                modelProName="opSend"
                radioMap={YesNoValues}
                model={model}
                inputRequired="false"
                inputConditionText=""
                inputText=""
                inputValue=""
                handleFunc={this.handleRadioOptionChange}
              />
              <CustomRadioButton
                labels={MarketSendLabel}
                groupName="Marketing Send"
                modelProName="marketSend"
                radioMap={YesNoValues}
                model={model}
                inputRequired="false"
                inputConditionText=""
                inputText=""
                inputValue=""
                handleFunc={this.handleRadioOptionChange}
              />
            </>
          )}
          <CustomRadioButton
            labels={DNSModeLabels}
            groupName="DNS Mode"
            modelProName="dnsMode"
            radioMap={DNSModeValues}
            model={model}
            inputRequired="false"
            inputConditionText=""
            inputText=""
            inputValue=""
            handleFunc={this.handleRadioOptionChange}
          />
          <CustomRadioButton
            labels={CreateBrandLabel}
            groupName="Create Brand"
            modelProName="createBrand"
            radioMap={YesNoValues}
            model={model}
            inputRequired="true"
            inputConditionText={['Yes']}
            inputText="please input Brand Name"
            inputValue="brandName"
            handleFunc={this.handleRadioOptionChange}
          />
          {model.createBrand === 'Yes' && (
            <>
              <CustomRadioButton
                labels={AppendTagsLabel}
                groupName="Append Tags"
                modelProName="appendTags"
                radioMap={YesNoValues}
                model={model}
                inputRequired="false"
                inputConditionText=""
                inputText=""
                inputValue=""
                handleFunc={this.handleRadioOptionChange}
              />
              <CustomRadioButton
                labels={IncludeChildrenLabel}
                groupName="Include Children"
                modelProName="includeChildren"
                radioMap={YesNoValues}
                model={model}
                inputRequired="false"
                inputConditionText=""
                inputText=""
                inputValue=""
                handleFunc={this.handleRadioOptionChange}
              />
            </>
          )}
          <CustomRadioButton
            labels={CreateCloudPage}
            groupName="Create CloudPage"
            modelProName="createCloudDomain"
            radioMap={YesNoValues}
            model={model}
            inputRequired="true"
            inputConditionText={['Yes']}
            inputText="please input Cloud page domain"
            inputValue="cloudDomainVal"
            handleFunc={this.handleRadioOptionChange}
          />
          <CustomRadioButton
            labels={RMMLabel}
            groupName="SetUp RMM"
            modelProName="setRmm"
            radioMap={YesNoValues}
            model={model}
            inputRequired="true"
            inputConditionText={['Yes']}
            inputText="please input RMM Forward Value"
            inputValue="rmmFwd"
            handleFunc={this.handleRadioOptionChange}
          />
          <CustomRadioButton
            labels={ZoneRequestLabel}
            groupName="DNS Zone Requested"
            modelProName="zoneRequested"
            radioMap={YesNoValues}
            model={model}
            inputRequired="false"
            inputConditionText=""
            inputText=""
            inputValue=""
            handleFunc={this.handleRadioOptionChange}
          />
          <div>
            <Button onClick={this.generateJson}>Generate Json</Button>
          </div>
          <IconSettings iconPath="/assets/icons">
            <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
              <div className="slds-col_padded">
                <strong>{jsonVal}</strong>
                {jsonVal && (
                  <Button
                    iconCategory="utility"
                    iconName="copy"
                    iconPosition="right"
                    label="copy"
                    onClick={() => navigator.clipboard.writeText(jsonVal)}
                  />
                )}
              </div>
            </section>
          </IconSettings>
        </div>
      </div>
    );
  }
}

export default TaskDetailGenerator;
