/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import RadioGroup from '@salesforce/design-system-react/components/radio-group';
import Radio from '@salesforce/design-system-react/components/radio-group/radio';

const CustomRadioButton = ({
  labels, groupName, modelProName, radioMap, model, inputRequired, inputConditionText, inputText, inputValue, handleFunc,
}) => (
  <div>
    <RadioGroup
      labels={labels}
      name={groupName}
      onChange={(event) => handleFunc(modelProName, event.target.value)}
    >
      {radioMap.map((value) => (
        <Radio
          key={value}
          id={groupName + value}
          labels={{ label: value }}
          value={value}
          checked={model[modelProName] === value}
        />
      ))}
    </RadioGroup>
    {inputRequired === 'true' && inputConditionText.includes(model[modelProName]) && (
      <input
        type="text"
        onChange={(event) => handleFunc(inputValue, event.target.value)}
        placeholder={inputText}
      />
    )}
  </div>
);

export default CustomRadioButton;
