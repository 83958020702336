import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from '@salesforce/design-system-react/components/button';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import Icon from '@salesforce/design-system-react/components/icon';
import Textarea from '@salesforce/design-system-react/components/textarea';

import './BounceVerify.scss';

const ValidDataTableCell = ({ children, ...props }) => {
  const { validProperty } = props;

  return (
    <DataTableCell {...props}>
      {props.item[validProperty]
        ? <Icon category="action" name="approval" size="x-small" />
        : <Icon category="action" name="close" size="x-small" />}
    </DataTableCell>
  );
};

ValidDataTableCell.displayName = DataTableCell.displayName;
ValidDataTableCell.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  validProperty: PropTypes.string.isRequired,
};

const RecordsOrErrorDataTableCell = ({ children, ...props }) => {
  const { errorProperty, item, recordsProperty } = props;
  const error = item[errorProperty];
  const records = item[recordsProperty];

  return (
    <DataTableCell {...props}>
      <div className="slds-text-color_error">{error}</div>
      {!error && records}
    </DataTableCell>
  );
};

RecordsOrErrorDataTableCell.displayName = DataTableCell.displayName;
RecordsOrErrorDataTableCell.propTypes = {
  children: PropTypes.node.isRequired,
  errorProperty: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  recordsProperty: PropTypes.string.isRequired,
};

const BounceVerify = () => {
  const [domains, setDomains] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [loading, setLoading] = useState(false);

  const checkDomains = async () => {
    setLoading(true);
    setDomainData([]);

    setDomainData(
      await Promise.all(domains.map(async (domain) => {
        const response = await fetch(`/bounce-verify/${domain}`);
        const data = await response.json();

        return {
          id: data.domain,
          valid: data.mx.valid && data.txt.valid,
          mxError: data.mx.error,
          mxRecords: data.mx.exchangers.join('\n'),
          mxValid: data.mx.valid,
          txtError: data.txt.error,
          txtRecords: data.txt.records.join('\n'),
          txtValid: data.txt.valid,
        };
      })),
    );

    setLoading(false);
  };

  const textAreaUpdated = ({ target: { value } }) => {
    if (value === '') setDomains([]);
    else {
      setDomains(value.split('\n')
        .map((domain) => domain.trim())
        .filter((domain) => domain.length > 0));
    }
  };

  return (
    <>
      <div className="slds-grid">
        <div className="slds-col slds-size_1-of-8" />
        <div className="slds-col slds-size_3-of-4">
          <div className="slds-text-heading_large slds-m-top_medium">
            Bounce Domain Verification
          </div>

          <Textarea
            className="domain-textarea slds-m-top_medium"
            onChange={textAreaUpdated}
            placeholder={'Enter one domain per line. Any domains that don\'t begin with "bounce." will have it prepended automatically.'}
          />

          <Button
            disabled={domains.length === 0 || loading}
            label="Check domains"
            onClick={checkDomains}
            variant="brand"
          />
        </div>
        <div className="slds-col slds-size_1-of-8" />
      </div>

      {domainData.length > 0 && (
        <div className="domain-data-container">
          <DataTable items={domainData} className="domain-data" fixedLayout>
            <DataTableColumn key="domain" label="Domain" property="id" />

            <DataTableColumn key="valid" label="Valid" width="50px">
              <ValidDataTableCell validProperty="valid" />
            </DataTableColumn>

            <DataTableColumn key="mx-details" label="MX Details">
              <RecordsOrErrorDataTableCell errorProperty="mxError" recordsProperty="mxRecords" />
            </DataTableColumn>

            <DataTableColumn key="txt-details" label="TXT Details">
              <RecordsOrErrorDataTableCell errorProperty="txtError" recordsProperty="txtRecords" />
            </DataTableColumn>

            <DataTableColumn key="mx-valid" label="MX Valid" width="80px">
              <ValidDataTableCell validProperty="mxValid" />
            </DataTableColumn>

            <DataTableColumn key="txt-valid" label="TXT Valid" width="80px">
              <ValidDataTableCell validProperty="txtValid" />
            </DataTableColumn>
          </DataTable>
        </div>
      )}
    </>
  );
};

export default BounceVerify;
