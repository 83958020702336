import PropTypes from 'prop-types';
import React from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';

const IPCheckDataTableCell = ({ children, ...props }) => {
  const { serviceKey } = props;
  const { url, valid } = props.item[serviceKey];

  return (
    <DataTableCell {...props}>
      <a href={url} target="_blank" rel="noreferrer">
        {valid ? '✅' : '❌'}
      </a>
    </DataTableCell>
  );
};

IPCheckDataTableCell.displayName = DataTableCell.displayName;
IPCheckDataTableCell.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  serviceKey: PropTypes.string.isRequired,
};

export default IPCheckDataTableCell;
